<template>
    <div>
        <van-search v-model="keyword" @search="handleSearch" @clear="handleClear" placeholder="请输入曲目号或者标题搜索"/>
        <van-list
            :offset="50"
            v-model="listLoading"
            :finished="listFinished"
            finished-text="没有更多了"
            @load="onLoad"
            :error.sync="error"
            error-text="加载,点击重新加载"
        >
            <van-row style="padding: 5px 0px">
                <van-col span="12" @click="handlePoem(poem)" v-for="poem in poems.data" :key="poem.id" >
                    <span class="poem-item van-ellipsis">{{ poem.track_sn + '. ' + poem.title }}</span>
                </van-col>
            </van-row>
        </van-list>
        <poem-reader :poem="poem" ref="poem" />
    </div>
</template>

<script>
import { getPoems } from '@/api/bible'
import PoemReader from '@/components/PoemReader'

export default {
    name: 'Poem',
    components: { PoemReader },
    data () {
        return {
            keyword: null,
            categoryLoading: true,
            listLoading: false,
            listFinished: false,
            error: false,
            poems: {
                meta: {
                    current_page: 0,
                    last_page: 0
                },
                data: []
            },
            poem: {
                url: '',
                title: '',
                track_sn: ''
            }
        }
    },
    methods: {
        handlePoem (poem) {
            this.poem = poem
            this.$refs.poem.show()
        },
        handleSearch () {
            this.refresh()
        },
        handleClear () {
            this.keyword = null
            this.refresh()
        },
        refresh () {
            this.poems.data = []
            this.poems.meta.current_page = 0
            this.listFinished = false
            this.listLoading = true
            this.onLoad();
        },
        onLoad () {
            getPoems({
                page: this.poems.meta.current_page + 1,
                keyword: this.keyword
            }).then(res => {
                res.data.unshift(...this.poems.data)
                this.poems = res
                this.listLoading = false
                // 数据全部加载完成
                if (res.meta.current_page === res.meta.last_page) {
                    this.listFinished = true
                }
            }).catch(() => {
                this.error = true
            })
        }
    }
};
</script>

<style lang="less">
    .poem-item {
        background: #fff;
        border-radius: 4px;
        font-size: 14px;
        line-height: 26px;
        display: block;
        width: auto;
        padding: 8px 10px;
        margin: 6px 8px;
    }
</style>
