import {axios} from '@/utils/request'

export function getCollectList(parameter) {
    return axios({
        url: 'users/collects',
        method: 'get',
        params: parameter
    })
}

export function getHistoryList(parameter) {
    return axios({
        url: 'users/histories',
        method: 'get',
        params: parameter
    })
}

export function updateUser(parameter) {
    return axios({
        url: 'users',
        method: 'put',
        data: parameter
    })
}
export function getUserInfo() {
    return axios({
        url: 'users/info',
        method: 'get'
    })
}

export function getUploadPayload(parameter) {
    return axios({
        url: 'uploads/payload',
        method: 'get',
        params: parameter,
    })
}

export function bindPhone(parameter) {
    return axios({
        url: 'users/phone',
        method: 'put',
        params: parameter
    })
}

export function verifiedActualName(parameter) {
    return axios({
        url: 'users/verified',
        method: 'put',
        params: parameter
    })
}

export function sendBindPhoneSms(parameter) {
    return axios({
        url: 'users/bind_phone_sms',
        method: 'post',
        data: parameter
    })
}

export function setLoginPassword(data) {
    return axios({
        url: 'users/login_password',
        method: 'post',
        data: data
    })
}

export function updateUserInfo(data) {
    return axios({
        url: 'users/info',
        method: 'post',
        data: data
    })
}





