<template>
    <div>
        <editor ref="editor" :init="initOptions" :initial-value="initContent || content" v-model="initContent" @onChange="handleEditorChange"/>
    </div>
</template>

<script>
import { isIos } from '@/utils/util'
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/themes/silver'
import 'tinymce/plugins/lists'
import 'tinymce/icons/default'

export default {
    name: 'TinymceEditor',
    components: { Editor },
    mounted() {
        tinymce.init({})
    },
    props: {
        content: {
            type: String,
            default () {
                return ''
            }
        }
    },
    watch: {
        content(val) {
            this.initContent = val
        }
    },
    methods: {
        handleEditorChange({ level }) {
            this.$emit('change', level.content)
        }
    },
    created() {
        this.initOptions = {
            document_base_url: this.publicPath,
            suffix: '.min',
            skin_url: `${this.publicPath}/static/tinymce/skins/ui/oxide`,
            content_css: `${this.publicPath}/static/tinymce/skins/content/default/content.min.css`,
            language_url: `${this.publicPath}/static/tinymce/langs/zh_CN.js`,
            language: 'zh_CN',
            height: 300,
            browser_spellcheck: true,
            branding: false,
            elementpath: false,
            statusbar: false,
            paste_data_images: true,
            menubar: false,
            plugins: 'lists',
            toolbar: 'undo h3 bold italic underline alignleft aligncenter blockquote numlist',
            content_style: 'body { margin: 0px } p { font-size: 15px }',
            placeholder: '记录点什么呢...',
            setup: (editor) => {
                editor.on('focusout', () => {
                    this.$emit('change', editor.getContent())
                    if (isIos()) {
                        window.scrollTo(0, 0)
                    }
                })
            }
        }
    },
    data() {
        return {
            publicPath: process.env.VUE_APP_STATIC_BASE_URL,
            initContent: ''
        }
    }
}
</script>
<style lang="less">
    .tox-tinymce {
        border: inherit !important;
    }
    .tox {
        .tox-toolbar {
            background: none !important;
        }
        .tox-toolbar__group {
            padding: 0px !important;
        }
    }
</style>
