<template>
    <div class="widget-note">
        <div class="popup-title">
            <p>听道笔记</p>
            <a href="" @click.prevent="handleReturned">回到聚会</a>
        </div>
        <van-panel :title="live.title" :desc="desc" v-if="user">
            <div class="note-editor">
                <tinymce-editor @change="handleSaveNote" :content="note.content" />
            </div>
            <template #footer>
                <span class="footer-tip" v-if="saving">自动保存中...</span>
                <span class="footer-tip" v-else>.</span>
            </template>
        </van-panel>
        <div class="note-login" v-else>
            <p class="login-tip">登录后即可使用笔记功能</p>
        </div>
    </div>
</template>

<script>
import { LOGIN_USER } from '@/utils/constants'
import TinymceEditor from '@/components/TinymceEditor'
import { storeNote, getLiveNote } from '@/api/note'
import debounce from 'lodash.debounce'
import mixin from '@/utils/mixin'

export default {
    name: "Note",
    mixins: [mixin],
    components: { TinymceEditor },
    props: {
        live: {
            type: Object,
            require: true
        }
    },
    data () {
        this.debouncedActualSave = debounce(this.handleActualSave, 300)
        return {
            noteShow: false,
            saving: false,
            actualContent: '',
            note: {
                id: 0,
                title: '',
                content: ''
            },
            user: null
        }
    },
    watch : {
        live () {
            this.queryInitNote()
        },
        user () {
            this.queryInitNote()
        }
    },
    created () {
        this.user = this.$ls.get(LOGIN_USER)
        this.$eventBus.$on('login-success', (data) => {
            this.user = data.user
            // this.$router.replace({ query: {} }).catch(err => err)
        })

        this.queryInitNote()
    },
    computed: {
        desc () {
            const author = this.live.author ? this.live.author + ' ' : ''
            return author + this.live.live_at.split(' ')[0]
        }
    },
    methods: {
        queryInitNote () {
            if (!this.user) {
                return
            }
            getLiveNote(this.live.id).then(res => {
                if (res.data) {
                    this.note = res.data
                }
            })
        },
        handleReturned () {
            this.handleActualSave()
            this.$emit('returned')
        },
        handleSaveNote (content) {
            this.actualContent = content
            this.debouncedActualSave(content)
        },
        handleActualSave () {
            if (this.saving || this.live.id === 0 || !this.actualContent) {
                return
            }
            this.saving = true
            storeNote({
                title: this.live.title,
                live_id: this.live.id,
                content: this.actualContent
            }).finally(() => {
                this.saving = false
            })
        }
    }
}
</script>

<style lang="less">
    .note-overlay {
        opacity: 0.1;
    }
    .popup-title {
        p {
            margin: 0px;
            padding: 16px 0px;
            background: #fff;
            font-size: 18px;
        }
        a {
            color: #1989fa;
            font-size: 14px;
            display: inline-block;
        }
    }
    .widget-note {
        .popup-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .van-panel__header {
            display: none;
            padding: 10px 0px;
        }
        .van-hairline--top-bottom {
            &::after {
                border: inherit;
            }
        }
        .van-cell:not(:last-child)::after {
            left: 0px;
        }
        .note-editor {
            margin-top: 10px;
        }
        .note-login {
            margin-top: 10px;
            text-align: center;
            .login-tip {
                color: #323233;
                font-size: 15px;
            }
        }
        .van-panel__footer {
            text-align: right;
            padding-left: 0px;
            padding-right: 0px;

            .footer-tip {
                color: #969799;
                font-size: 12px;
            }
        }
    }
</style>
