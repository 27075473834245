<template>
    <van-uploader v-model="fileList" :max-count="1" :max-size="20 * 1024 * 1024" @oversize="onOversize" :before-read="asyncBeforeRead" :after-read="afterRead" />
</template>

<script>
import { getUploadPayload } from '@/api/common'
import axios from 'axios'

export default {
    name: "ImageUploader",
    props: {
        url: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            params: {},
            fileList: []
        }
    },
    created() {
        this.fileList = [{
            url: this.url,
            isImage: true
        }]
    },
    methods: {
        onOversize(file) {
            console.log(file)
            this.$notify({ type: 'warning', message: '文件大小不能超过20M' })
        },
        asyncBeforeRead(file) {
            return new Promise((resolve) => {
                const params = {
                    filename: file.name,
                    content_type: file.type,
                    upload_type: 'avatars'
                }
                getUploadPayload(params).then((res) => {
                    this.params = res.data
                    resolve()
                })
            })
        },
        afterRead(file) {
            console.log(file)
            // 此时可以自行将文件上传至服务器
            const formData = new FormData()
            file.status = 'uploading';
            file.message = '上传中';

            for (const [key, value] of Object.entries(this.params.upload_params)) {
                formData.append(key, value);
            }

            formData.append("file", file.file);
            axios.post(this.params.action, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                this.$emit('done', this.params.url)
                console.log(this.params.url)
                file.status = 'done'
            }).catch(() => {
                file.status = 'failed'
                file.message = '上传失败'
            })
        }
    }
}
</script>

<style scoped>

</style>
