<template>
    <div>
        <van-popup
            v-if="widgets.includes('note')"
            v-model="noteShow"
            position="bottom"
            class="widget-note-popup"
            :overlay="false"
            safe-area-inset-bottom
            close-on-popstate
            :style="{ height: actualHeight  }"
        >
            <vue-lazy-component>
                <note ref="note" :live="live" @returned="noteShow = false" />
                <van-loading style="text-align: center" type="spinner" size="16px" slot="skeleton" />
            </vue-lazy-component>
        </van-popup>
        <van-popup
            v-if="widgets.includes('chat')"
            v-model="chatShow"
            position="bottom"
            class="widget-chat-popup"
            :overlay="false"
            safe-area-inset-bottom
            close-on-popstate
            :style="{ height: actualHeight  }"
        >
            <vue-lazy-component>
                <chat ref="chat" :live="live" @receive-message="handleReceiveMessage" @returned="chatShow = false" />
                <van-loading style="text-align: center" type="spinner" size="16px" slot="skeleton" />
            </vue-lazy-component>
        </van-popup>

        <div class="action-btns">
            <van-button v-if="widgets.includes('note')" type="info" icon="edit" class="custom-btn" @click="noteShow = true"></van-button>
            <!--<van-button v-if="widgets.includes('chat')" type="info" icon="chat-o" class="custom-btn" @click="handleChat">-->
                <!--<span class="dot" v-if="haveUnread"></span>-->
            <!--</van-button>-->
<!--            <van-button v-if="widgets.includes('home')" type="info" icon="wap-home-o" class="custom-btn" @click="$router.replace('/video')"></van-button>-->
        </div>
    </div>
</template>

<script>
import Note from './Note'
import Chat from './Chat'
import mixin from '@/utils/mixin'

export default {
    name: 'LiveWidget',
    mixins: [mixin],
    components: { Note, Chat },
    props: {
        live: {
            type: Object,
            require: true
        },
        widgets: {
            type: Array,
            required: false,
            default () {
                return ['note', 'chat', 'home']
            }
        }
    },
    data () {
        return {
            haveUnread: false,
            chatShow: false,
            noteShow: false
        }
    },
    mounted () {
        if (this.$route.query.loginType === 'note') {
            this.noteShow = true
        }
        if (this.$route.query.loginType === 'chat') {
            this.chatShow = true
        }
    },
    methods: {
        handleReceiveMessage () {
            if (!this.$refs.chat.chatShow) {
                this.haveUnread = true
            }
        },
        handleChat () {
            this.chatShow = true
            this.haveUnread = false
        }
    }
}
</script>

<style lang="less">
    .action-btns {
        padding: 24px 16px;
        flex-direction: column;
        align-items: flex-end;
        box-sizing: border-box;
        position: fixed;
        right: 0;
        bottom: 0;
        display: flex;

        .custom-btn {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            padding: 0px;
            margin: 8px 0px;
            line-height: inherit;
            box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
        }
        .van-col {
            text-align: right;
        }
        .van-button__text {
            position: absolute;
            top: 10px;
            right: 10px;
            .dot {
                width: 5px;
                height: 5px;
                background: #ee0a24;
                border-radius: 50%;
                display: block;
            }
        }
    }
    .widget-chat-popup, .widget-note-popup {
        padding: 0px 16px;
        box-sizing: border-box;
        height: 67%;
    }
</style>
