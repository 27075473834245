<template>
    <middle-loading :loading="loading" v-if="loading" />

    <div v-else>
        <van-empty v-if="notFound" description="直播不存在" />
        <div v-else>
            <div id="xg" ref="xg"></div>

            <van-tabs v-model="active" sticky :offset-top="offsetTop">
                <van-tab title="概览">
                    <div class="live-content">
                        <p v-html="playback.media.content"></p>
                    </div>
                </van-tab>
                <van-tab title="圣经"><bible /></van-tab>
                <van-tab title="赞美诗"><poem /></van-tab>
            </van-tabs>
        </div>
        <live-widget :live="playback" :widgets="['note']" />
    </div>
</template>

<script>
import wechatMixin from '@/utils/mixin'
import { getLiveDetail } from '@/api/live'
import { isAndroid, isWeixinBrowser } from '@/utils/util'
import Poem from '@/components/Poem'
import Bible from '@/components/Bible'
import LiveWidget from '@/view/live/widgets/LiveWidget'
import Player from 'xgplayer'
import 'xgplayer-mp4'
import MiddleLoading from '@/components/MiddleLoading'

export default {
    name: 'PlaybackShow',
    mixins: [wechatMixin],
    components: { Bible, Poem, LiveWidget, MiddleLoading },
    metaInfo () {
        return {
            title: this.playback.title
        }
    },
    data () {
        return {
            loading: false,
            playback: {},
            player: null,
            active: 0,
            offsetTop: 0,
            notFound: false
        }
    },
    created() {
        this.loading = true
        getLiveDetail(this.$route.params.id).then(res => {
            this.loading = false
            this.playback = res.data
            this.$nextTick(() => {
                this.player = new Player(Object.assign(this.playerOptions, {
                    url: this.playback.media.url,
                    poster: this.playback.poster
                }))
                this.offsetTop = this.$refs.xg.offsetHeight
            })

            this.registerWechatShare({
                title: this.playback.title,
                description: this.playback.media.content,
                thumb: this.playback.thumb
            })
        }, () => {
            this.notFound = true
        }).finally(() => {
            this.loading = false
        })
    },
    computed: {
        playerOptions () {
            return {
                id: 'xg',
                url: '',
                fluid: true,
                ignores: ['volume'],
                playsinline: this.playsinline,
                noLog: true,
                autoplay: true,
                airplay: true,
                closeVideoClick: false,
                closeVideoTouch: false,
                'x5-video-player-type': 'h5-page',
                'x5-video-player-fullscreen': true,
                preloadTime: 10
            }
        },
        playsinline () {
            if (!isAndroid()) {
                return true
            }

            if (isWeixinBrowser()) {
                return false
            }
            return true
        }
    }
}
</script>
<style lang="less">
    .xgplayer-skin-default .xgplayer-enter .xgplayer-enter-spinner {
        width: 20px !important;
        height: 20px !important;
    }
    .live-content {
        padding: 24px;
        font-size: 16px;
        line-height: 24px;

        p {
            margin: 0px;
        }
    }
</style>
