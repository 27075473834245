<template>
    <div>
        <div class="chat-item chat-self" v-if="isRight">
            <div class="chat-content">
                <span class="nickname">{{ record.user.nickname }}</span>
                <p class="content">{{ record.content }}</p>
            </div>
            <van-image
                @click="show = true"
                round
                fit="cover"
                :src="record.user.avatar"
            />
        </div>
        <div class="chat-item" v-else>
            <van-image
                round
                fit="cover"
                :src="record.user.avatar"
            />
            <div class="chat-content">
                <van-tag plain type="primary" v-if="record.is_admin">管理员</van-tag>
                <span class="nickname">{{ record.user.nickname }}</span>
                <span class="time">{{ record.created_at }}</span>
                <p class="content">
                    <span class="mention" v-if="isMentionMe">[有人@我]</span>
                    {{ record.content }}
                </p>
            </div>
        </div>
        <van-action-sheet v-model="show" title="更改用户信息">
            <div class="content">
                <van-form @submit="onSubmit">
                    <van-field
                        v-model="form.nickname"
                        name="nickname"
                        label="昵称"
                        placeholder="昵称"
                        :rules="[{ required: true, message: '请输入昵称' }]"
                    />
                    <van-field name="uploader" label="头像">
                        <template #input>
                            <ImageUploader :url="user.avatar" @done="onImageUploadSuccess" />
                        </template>
                    </van-field>
                    <div style="margin: 16px;">
                        <van-button round block type="info" native-type="submit" :loading="loading">提交</van-button>
                    </div>
                </van-form>
            </div>
        </van-action-sheet>
    </div>
</template>

<script>
import { updateUserInfo } from '@/api/user'
import ImageUploader from '@/components/ImageUploader'
import {LOGIN_USER} from "@/utils/constants";
export default {
    name: "ChatItem",
    components: {ImageUploader},
    props: {
        record: Object,
        user: Object
    },
    data () {
        return {
            loading: false,
            show: false,
            form: {
                nickname: '',
                avatar: ''
            }
        }
    },
    created() {
        this.form = {
            nickname: this.user.nickname,
            avatar: this.user.avatar
        }
    },
    methods: {
        onSubmit () {
            this.loading = true
            updateUserInfo(this.form).then(res => {
                this.$notify({ type: 'success', message: '操作成功, 请刷新页面' })
                this.$ls.set(LOGIN_USER, res.data)

                this.show = false
            }).finally(() => {
                this.loading = false
            })
        },
        onImageUploadSuccess (url) {
            this.form.avatar = url
        }
    },
    computed: {
        isRight() {
            const position = this.record.user.id === this.user.id ? 'right' : 'left'
            return position === 'right'
        },
        isMentionMe() {
            if (!this.record.is_mention || this.record.is_mention <= 0) {
                return false
            }
            //这里是@全部人
            if (!this.record.mention_users) {
                return true
            }
            const index = this.record.mention_users.findIndex((mentionUserId) => {
                return mentionUserId === 0 || mentionUserId === this.userId
            })
            return index !== -1
        }
    }
}
</script>

<style lang="less">
    .chat-self {
        justify-content: flex-end;

        .chat-content {
            margin-right: 8px;
            max-width: 60%;
            text-align: right;
        }
    }

    .chat-item {
        display: flex;
        align-items: flex-start;
        padding: 16px 0px;

        .chat-content {
            margin-left: 8px;
            max-width: 60%;

            .nickname, .time {
                margin-left: 4px;
                color: #969799;
                font-size: 12px;
            }
            .content {
                margin: 0px;
                color: #323233;
                font-size: 14px;

                .mention {
                    color: #ee0a24;
                }
            }
        }

        img {
            width: 36px;
            height: 36px;
        }
    }
</style>
