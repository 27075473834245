<template>
    <van-empty description="聊天已关闭" v-if="isMuted">
        <van-button round plain type="default" @click="setMutedStatus(false)" class="open-chat-button">开启聊天</van-button>
    </van-empty>
    <div class="widget-chat" v-else>
        <template>
            <van-notice-bar v-if="reconnecting" :scrollable="false">断线重连中...</van-notice-bar>
            <div class="chat-body">
                <middle-loading :loading="loading" v-if="loading" />
                <van-empty v-if="!loading && relatedChats.length === 0" description="暂无内容" />
                <chat-item
                    :id="'chat-record-' + i"
                    :key="i"
                    v-for="(chat, i) in relatedChats"
                    :record="chat"
                    :user="user"
                />
            </div>
            <div style="height: 87px; width: 100%"></div>
            <div class="chat-footer" v-if="user.id !== 0">
                <div class="van-hairline--top">
                    <van-field
                        v-model="msg"
                        center
                        clearable
                        :placeholder="messagePlaceholder"
                        :disabled="disabledButton"
                    >
                        <template #button v-if="!forbiddenChatStatus">
                            <van-button size="small" type="info" @click="handleSendMessage" :disabled="disabledButton" style="margin-right: 4px">发送</van-button>
                            <van-button size="small" type="default" @click="setMutedStatus(true)" v-if="!isMuted">关闭聊天</van-button>
                        </template>
                    </van-field>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import ChatItem from './ChatItem'
import io from 'socket.io-client'
import pick from 'lodash.pick'
import { getChatRecords, getIoServer } from '@/api/live'
import MiddleLoading from '@/components/MiddleLoading'

export default {
    name: 'Chat',
    components: { ChatItem, MiddleLoading },
    props: {
        live: {
            type: Object,
            require: true
        },
        user: {
            type: Object,
            require: true
        }
    },
    computed: {
        relatedChats () {
            const allChats = this.baseChats.concat(this.chats)
            return allChats.filter((item) => {
                return this.deleteMessages.indexOf(item.id) === -1
            })
        },
        messagePlaceholder () {
            if (this.forbiddenChatStatus) {
                return '全员禁言中'
            }
            if (this.mute) {
                return '互动已关闭'
            }
            return '请输入内容发送'
        },
        disabledButton () {
            return this.reconnecting || this.isMuted || this.forbiddenChatStatus
        }
    },
    data () {
        return {
            loading: false,
            socket: null,
            msg: '',
            chats: [],
            baseChats: [],
            deleteMessages: [],
            isMuted: false,
            reconnecting: false,
            forbiddenChatStatus: false
        }
    },
    updated (){
        // const id = 'chat-record-' + (this.relatedChats.length - 1)
        // let ele = document.getElementById(id)
        // if (ele) {
        //     ele.scrollIntoView({
        //         block: 'start',
        //         behavior: 'smooth'
        //     })
        // }
    },
    mounted () {
        //初始化聊天
        this.initSocket()
        this.queryInitChatRecords()
    },
    created () {
        //处理禁言状态
        this.forbiddenChatStatus = this.live.meta.is_forbidden_chat === 1
    },
    methods: {
        queryInitChatRecords () {
            this.loading = true
            getChatRecords(this.live.id).then(res => {
                this.baseChats = res.data
            }).finally(() => {
                this.loading = false
            })
        },
        setMutedStatus (status) {
            this.isMuted = status
            if (status) {   //静音
                this.socket.disconnect()
            } else {
                this.socket.connect()
            }
        },
        handleServerMessage (data) {
            this.chats.push(data)
        },
        handleForbiddenChat (data) {
            if (data.user_id === 0) {   //全员禁止
                this.forbiddenChatStatus = true
            }
        },
        handleDismissForbiddenChat (data) {
            if (data.user_id === 0) {   //全员解禁
                this.forbiddenChatStatus = false
            }
        },
        handleMessageDelete (data) {
            this.deleteMessages.push(data.chat_record_id)
        },
        initSocket () {
            getIoServer().then(res => {
                this.socket = io(res.data.dsn + '/chat', { transports: ['websocket'] });

                this.socket.on('connect', () => {
                    this.socket.emit('OnJoinRoom', { live_id: this.live.id, user_id: this.user.id });
                })

                //收到服务短消息
                this.socket.on('OnServerMessage', this.handleServerMessage)

                //禁言操作
                this.socket.on('OnForbiddenChat', this.handleForbiddenChat)

                //解除禁言操作
                this.socket.on('OnDismissForbiddenChat', this.handleDismissForbiddenChat)

                //删除消息操作
                this.socket.on('OnMessageDelete', this.handleMessageDelete)

                //4.重新连接成功事件
                this.socket.on('reconnect', () => {
                    this.reconnecting = false
                })

                //2.开始重新连接中
                this.socket.on('reconnecting', () => {
                    this.reconnecting = true
                })
            })
        },
        handleSendMessage () {
            let trimMsg = this.msg.trim()
            if (!trimMsg || !this.socket) {
                return
            }
            const message = {
                user: pick(this.user, ['id', 'nickname', 'avatar']),
                live: pick(this.live, ['id', 'title']),
                content: trimMsg,
                is_admin: 0
            }
            this.socket.emit('OnClientMessage', message, () => {
                this.msg = ''
            })
        }
    }
}
</script>

<style lang="less">
    .chat-title {
        position: fixed;
        width: 100%;
        background: #fff;
        z-index: 999;
        left: 0;
        padding: 0px 16px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            margin: 0px;
            padding: 16px 0px;
            background: #fff;
            font-size: 18px;
        }
        a {
            color: #1989fa;
            font-size: 14px;
            display: inline-block;
        }
    }
    .open-chat-button {
        width: 160px;
        height: 40px;
    }
    .widget-chat {
        .popup-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .chat-body {
            padding: 0px 16px;
            overflow-y: scroll;
        }
        .is-muted {
            filter: blur(8px);
        }
        .chat-footer {
            width: 100%;
            position: fixed;
            left: 0;
            bottom: 0;
            padding: 0px 16px;
            box-sizing: border-box;
            background: #fff;
            padding-bottom: 34px;

            .van-cell {
                padding: 10px 0px;
            }
            .hairline-top::after {
                position: absolute;
                box-sizing: border-box;
                content: ' ';
                pointer-events: none;
                top: -50%;
                right: -50%;
                bottom: -50%;
                left: -50%;
                border: 1px solid #ebedf0;
                transform: scale(.5);
            }
        }
    }
</style>
